<template>
  <section class="expertises section-padding" v-if="avis.length > 0">
    <div class="add-padding d-flex flex-column align-items-center">
      <h2 class="titre-section">
        {{ (title) ? title : 'LES AVIS DE NOS CLIENTS' }}
      </h2>
      <div class="row">
        <div class="wrap" v-if="isLoading">
          <div class="spinner-wrap">
            <div class="spinner">
              <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
            </div>
          </div>
        </div>
      </div>
      <div class="slider-wrapper">
        <div class="clientSliderSix row">
          <swiper ref="mySwiper" :slides-per-view="swiperNumber"
            :autoplay="{ delay: 10000, disableOnInteraction: true }">
            <swiper-slide v-for="item in avis" :key="item.idA">
              <div class="item">
                <div class="feedback-wrapper">
                  <div class="stars d-flex justify-content-between mb-25 gap-15 title-minHeight">
                    <div class="d-flex">
                      <div v-for="i in item.starNumber">
                        <img :key="i" alt="stars.png" src="/images/pages/accueil/stars.png" />
                      </div>
                    </div>
                    <div>
                      <span class="text-uppercase">{{ item.nameTraining }}</span>
                    </div>
                  </div>
                  <p class="text-start fixed-size" v-html="item.describeA"></p>
                  <div class="d-flex align-items-center footer-height">
                    <h6 class="name d-flex align-items-center">
                      <div class="logoAvis">
                        <img :src='imageUrl(item.idA, item.siteIdA, item.pictureThumbA)' :alt="item.titleSubA"
                          v-if="item.pictureThumbA.length > 0">
                        <img src='/images/icon/avis-neutre.png' :alt="item.titleSubA" v-else>
                      </div>
                      <span>{{ item.titleSubA }}</span>
                    </h6>
                  </div>
                </div>
                <!-- /.feedback-wrapper -->
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>


<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import axios from 'axios';

SwiperCore.use([Navigation, Autoplay]);

// defineProps({
//   title: String,
//   idCategory: Number
// })


const props = defineProps({
  idCategory: Number,
  title: String,
});
const { idCategory } = toRefs(props);


const isLoading = ref(true);
const swiperNumber = ref(3);
const avis = ref([]);

const avisCategory = ref([
  {
    "name": "Soft Skills",
    "idCategory": 76,
  },
  {
    "name": "Ressources Humaines et RSE",
    "idCategory": 77,
  },
  {
    "name": "Leadership et Management",
    "idCategory": 78,
  }, {
    "name": "Organisme de formation",
    "idCategory": 79,
  },
  {
    "name": "Bilan de compétences",
    "idCategory": 80,
  }
])

onMounted(async () => {
  try {
    let allAvis = [];

    // Suppression de la boucle for et utilisation directe de oIdCat: 80
    const response = await axios({
      method: 'post',
      url: 'https://ws2-gbbu02.graphibox.eu/EpArticles.ws',
      data: { pKey: 'EmPA5d6sdse5z6ldfjPAEhazozp5ksJEZkzn872', pFct: 'GetArticles', pIdCat: idCategory.value },
    });
    const dataArray = Array.isArray(response.data) ? response.data : [];

    const processedAvis = dataArray.map(avis => {
      if (avis.titleA && avis.titleA.includes("|")) {
        const splitTitleA = avis.titleA.split("|");
        avis.nameTraining = splitTitleA[0];
        avis.starNumber = parseInt(splitTitleA[1], 10);
      } else {
        avis.nameTraining = avis.titleA;
        avis.starNumber = 5;
      }
      let maxLengthTitle = 40;
      if (avis.nameTraining && avis.nameTraining.length > maxLengthTitle) {
        avis.nameTraining = avis.nameTraining.substring(0, maxLengthTitle) + "...";
      }
      let maxLengthDescription = 250;
      if (avis.describeA && avis.describeA.length > maxLengthDescription) {
        avis.describeA = avis.describeA.substring(0, maxLengthDescription) + "...";
      }
      return avis;
    });
    allAvis = processedAvis;

    avis.value = allAvis;

    if (avis.value.length < 3) {
      swiperNumber.value = avis.value.length;
    }
    isLoading.value = false;
  } catch (error) {
    console.error("Une erreur s'est produite lors de la récupération des avis :", error);
    isLoading.value = false;
  }
});



</script>

<style scoped>
.title-minHeight {
  min-height: 6vh;
  ;
}

.footer-height {
  height: 2vh;
}

.fixed-size {
  height: 18.5vh;
  max-height: 18.5vh;
  min-height: 18.5vh;
}

.logoAvis {
  max-width: 15%;
  margin-right: 3rem;
}


.spinner-wrap {
  top: 150px;
}

.titre-section {
  color: var(--accent, #25233E);
  text-align: center;
  font-family: Nunito;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 111.5%;
  letter-spacing: -0.825px;
  text-transform: uppercase;
}

.section-padding {
  padding-bottom: 4rem;
}


.item {
  border-radius: 0.5625rem;
  background: #FFF;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.08);
}

.text-start {
  line-height: normal !important;
}

@media only screen and (max-width: 320px) {
  .section-padding {
    padding-bottom: 0;
    padding-top: 3rem;
  }

  .d-flex.justify-content-center {
    max-width: 80%;
    margin: auto;
    text-align: center;
  }

  .add-padding {
    padding-bottom: 3rem;
  }

  h2 {
    font-size: 20px !important;
  }

  .text-uppercase {
    font-size: 25px !important;
  }

  .text-start {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 599px) {
  .section-padding {
    padding: 0px;
  }

  .feedback-wrapper,
  .stars,
  .d-flex,
  .text-uppercase,
  .text-start,
  .d-flex.align-items-center,
  .name {
    transform: scale(0.85);
  }

  img {
    transform: scale(1);
  }

  .feedback-wrapper,
  .text-uppercase,
  .text-start {
    font-size: 20px !important;
  }
}


@media only screen and (min-width: 1000px) and (max-width: 1024px) {

  .feedback-wrapper,
  .stars,
  .d-flex,
  .text-uppercase,
  .text-start,
  .d-flex.align-items-center,
  .name {
    transform: scale(0.9);
  }

  img {
    transform: scale(1.1);
  }

  .feedback-wrapper,
  .text-uppercase,
  .text-start {
    font-size: 1.2em;
  }
}
</style>